<template>
    <div>
        <div class="download-page">
            <div class="bubbles" v-if="!isMobile">
                <div class="bubble bubble-1"></div>
                <div class="bubble bubble-2"></div>
                <div class="bubble bubble-3"></div>
                <div class="bubble bubble-4"></div>
                <div class="bubble bubble-5"></div>
            </div>

            <div class="bubbles" v-else>
                <div class="bubble bubble-mobile-1"></div>
                <div class="bubble bubble-mobile-2"></div>
                <div class="bubble bubble-mobile-3"></div>
            </div>

            <div class="content-landing">
                <div class="logo"></div>

                <div class="title-update">
                    {{ $t('popup.update.title') }}
                </div>
                <div class="title-description-update" v-if="!min">
                    {{ $t('popup.update.message') }}
                </div>
                <div class="title-description-update" v-else>
                    {{ $t('popup.update.message_min') }}
                </div>
                <div class="footer-actions-update">
                    <div class="download-button" @click="download()">
                        {{ $t('popup.update.text_download') }}
                    </div>
                    <div class="update-later" v-if="!min" @click="updateLater()">
                        {{ $t('popup.update.update_later') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import { Api } from '@/core/api.js'
const api = new Api()

export default {
    props: {
        min: {
            type: undefined,
            default: false
        }
    },
    data() {
        return {
            title: i18n.t('popup.update.title'),
            message: i18n.t('popup.update.message'),
            textDownload: i18n.t('popup.update.text_download'),
            isMobile: false,
            urlIOS: 'https://apps.apple.com/es/app/andy-appcc/id6447616563'
        }
    },
    computed: {
        isIOS() {
            return this.$store.getters['getIsIOS']
        },
        currentVersion() {
            return this.$store.getters['getServerVersionCurrent']
        }
    },
    mounted() {
        this.isMobile = isMobile()
        window.addEventListener('resize', this.handleResize)
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        updateLater() {
            this.$emit('updateLater')
        },
        download() {
            var self = this

            if (this.$store.getters.getIsApp) {
                const app_android = this.$store.getters['login/getAppAndroid']

                if (app_android && app_android.trim() != '') {
                    self.$overlay.loading()
                    // if (app_android.trim().match(/\.apk/gi)) {
                    //     var fileTransfer = new FileTransfer()
                    //     fileTransfer.download(
                    //         app_android.trim(),
                    //         cordova.file.externalDataDirectory + 'andy.apk',
                    //         function (entry) {
                    //             log('download complete')
                    //             log(entry)
                    //             entry.file(function (file) {
                    //                 log(file)
                    //                 cordova.plugins.fileOpener2.open(cordova.file.externalDataDirectory + 'andy.apk', 'application/vnd.android.package-archive', {
                    //                     error: function (e) {
                    //                         log(e)
                    //                         self.$overlay.hide()
                    //                         window.open(app_android.trim(), '_system')
                    //                     },
                    //                     success: function () {
                    //                         self.$overlay.hide()
                    //                         log('file opened successfully')
                    //                     }
                    //                 })
                    //             })
                    //         },
                    //         function (error) {
                    //             const app_android = this.$store.getters['login/getAppAndroid']
                    //             log(error)
                    //             self.$overlay.hide()
                    //             window.open(app_android.trim(), '_system')
                    //         }
                    //     )
                    // } else {
                    //     self.$overlay.hide()
                    //     window.open(app_android.trim(), '_system')
                    // }
                    const accountLogin = this.$store.getters['login/getAccount']
                    api.get('login/accountPrefix/' + accountLogin).then((response) => {
                        this.$router.push(`/download/${response.data.prefix}`)
                        self.$overlay.hide()
                    })
                } else {
                    self.$overlay.hide()
                    if (this.isIOS) {
                        window.open(this.urlIOS, '_blank')
                    } else {
                        cordova.plugins.market.open(process.env.VUE_APP_ID_APP_ANDROID)
                    }
                }
            } else {
                window.location.reload()
            }
        },
        handleResize() {
            this.isMobile = isMobile()
        }
    }
}

function isMobile() {
    return window.innerWidth <= 1036
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
* {
    font-family: $text;
}

.download-page {
    min-height: 100vh;
    background-color: $color-primary-500;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.bubbles {
    position: absolute;
    inset: 0;
}

.bubble {
    position: absolute;
    border-radius: 50%;
    background: rgba(237, 245, 245, 0.19);
}

.bubble-mobile-1 {
    width: 690px;
    height: 690px;
    bottom: -490px;
    left: -380px;
}

.bubble-mobile-2 {
    width: 328px;
    height: 328px;
    top: -238px;
    right: -2px;
}

.bubble-mobile-3 {
    width: 328px;
    height: 328px;
    top: -168px;
    right: -254px;
}

.bubble-1 {
    width: 690px;
    height: 690px;
    bottom: -90px;
    left: -280px;
}

.bubble-2 {
    width: 437px;
    height: 437px;
    bottom: -165px;
    left: -173px;
}

.bubble-3 {
    width: 328px;
    height: 328px;
    top: -238px;
    right: 390px;
}

.bubble-4 {
    width: 328px;
    height: 328px;
    top: -160px;
    right: 150px;
}

.bubble-5 {
    width: 437px;
    height: 437px;
    top: -50px;
    right: -115px;
}

.content-landing {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    top: 14% !important;
    .logo {
        background-image: img('alex_carita_12.svg');
        background-repeat: no-repeat;
        width: 116px;
        height: 120px;
        margin-bottom: 1.5rem;
    }
}

.title-update {
    width: 80%;
    color: $color-white;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 0.3rem;
    align-content: center;
    align-items: center;
    text-align: center;
    line-height: 28.8px;
}

.title-description-update {
    width: 80%;
    color: $color-white;
    font-size: 18px;
    font-weight: 400;
    margin-top: 32px;
    margin-bottom: 2.5rem;
    align-content: center;
    align-items: center;
    text-align: center;
    line-height: 28.8px;
}

.update-later {
    color: $color-white;
    font-size: 18px;
    font-weight: 500;
    margin-top: 64px;
    align-content: center;
    align-items: center;
    text-align: center;
    line-height: 26px;
    text-decoration: underline;
    cursor: pointer;
}

.download-button {
    background-color: $color-white;
    color: $color-primary-500;
    border: none;
    border-radius: 4px;
    padding: 12px 24px;
    font-size: 18px;
    font-family: $text-bold;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 5rem;
}

.download-button:hover {
    background-color: $color-white;
}

.download-button svg {
    width: 20px;
    height: 20px;
}

@media (min-width: 500px) {
    .title-update,
    .title-description-update {
        width: 100%;
    }
}
</style>
