<template>
    <div id="user-box" :class="[$mq, 'overlay']" @click.self="closeBox">
        <div class="menu">
            <div class="close-menu-icon" v-if="!['portrait'].includes($mq)">
                <div class="icon" @click="closeBox"></div>
            </div>
            <!-- USER INFO -->
            <div class="user-info">
                <employee class="selected transparent no-click" :class="{ 'employee-small': false }" :employee="user" :rol="role"></employee>
            </div>
            <!-- USER ACTIONS -->
            <div class="user-actions hide-scrollbar">
                <!-- ONLY AUDITOR ACTION-->
                <div class="action local" @click="changeLocal" v-if="auditor">
                    <div class="icon"></div>
                    <div class="text">{{ $t('user.change_local') }}</div>
                </div>

                <div class="action profile" @click="userConfig()">
                    <div class="icon"></div>
                    <div class="text">{{ $t('user.see_profile') }}</div>
                </div>
                <div class="action logout" @click="auditorLogout" v-if="auditor">
                    <div class="icon"></div>
                    <div class="text">{{ $t('user.logout') }}</div>
                </div>
                <div class="action logout" @click="userLogout" v-else>
                    <div class="icon"></div>
                    <div class="text">{{ $t('user.logout') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import employee from '@/components/domain/login/employee'

export default {
    components: { employee },
    data() {
        return {}
    },
    props: {
        auditor: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        emp_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },

        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.emp_id)

            if (employee) {
                return employee
            }
        },

        role() {
            if (this.user) {
                if (this.user.rol) {
                    return this.$store.getters['employee/getRol'](this.user.rol)
                }
            }
        },
        home() {
            return this.$store.getters['getHome'] ? this.$store.getters['getHome'] : '/home'
        },
        location() {
            return this.$store.getters['loginUser/getLocalLocation']
        },
        // KITCHEN MODE FEATURE FLAG
        kitchenModeFeatureFlag() {
            const kitchenModeFeatureFlag = this.$store.getters['login/getKitchenModeFeatureFlag']

            return kitchenModeFeatureFlag && kitchenModeFeatureFlag === 1 ? true : false
        }
    },
    methods: {
        // USER ACTIONS
        userConfig() {
            this.closeBox()
            this.$router.push({
                name: 'user'
            })
        },

        changeLocal(event) {
            event.preventDefault()
            event.stopPropagation()
            this.closeBox()
            let changesToSyncronize = false
            var self = this
            const employeeTranslations = {
                confirm: {
                    message: self.$tc('config.changes_sync') + ' ' + self.$tc('config.changes_sync_logout', changesToSyncronize, { number: changesToSyncronize }) + ' ' + self.$tc('config.changes_sync_logout2', changesToSyncronize) + ' ' + self.$tc('config.changes_sync_logout3'),
                    textSave: self.$t('popup.confirm.stay_conected'),
                    textCancel: self.$t('popup.cancel.close_session')
                }
            }
            this.$store.dispatch('getCountSyncItems').then((count) => {
                changesToSyncronize = count
                if (count > 0) {
                    employeeTranslations.confirm.message = changesToSyncronize > 0 ? employeeTranslations.confirm.message.replace('false', changesToSyncronize) : employeeTranslations.confirm.message
                    self.$popup.confirm({
                        message: employeeTranslations.confirm.message,
                        // title: self.$t('popup.confirm.title'),
                        textSave: employeeTranslations.confirm.textSave,
                        textCancel: employeeTranslations.confirm.textCancel,
                        callSave: function () {
                            self.$popup.close()
                        },
                        callCancel: function () {
                            self.$store.dispatch('login/logoutAuditorLocal')
                            self.$router.push('/audit')
                        }
                    })
                } else {
                    self.$store.dispatch('login/logoutAuditorLocal')
                    if (self.$route.path.includes('/audit')) return
                    self.$router.push('/audit')
                }
            })
        },

        auditorLogout(event) {
            event.preventDefault()
            event.stopPropagation()
            this.closeBox()

            var self = this
            const employee_id = this.$store.getters['loginUser/getLocalEmployee']
            let changesToSyncronize = false

            const employeeTranslations = {
                confirm: {
                    message: self.$tc('config.changes_sync') + ' ' + self.$tc('config.changes_sync_logout', changesToSyncronize, { number: changesToSyncronize }) + ' ' + self.$tc('config.changes_sync_logout2', changesToSyncronize) + ' ' + self.$tc('config.changes_sync_logout3'),
                    textSave: self.$t('popup.confirm.stay_conected'),
                    textCancel: self.$t('popup.cancel.close_session')
                },
                disconnect: {
                    message: self.$t('login.logout_auditor_desc', { user: self.user.name }),
                    textSave: self.$t('popup.local_logout.text_save'),
                    textCancel: self.$t('popup.local_logout.text_cancel')
                }
            }

            this.$store.dispatch('getCountSyncItems').then((count) => {
                changesToSyncronize = count
                if (count > 0) {
                    employeeTranslations.confirm.message = changesToSyncronize > 0 ? employeeTranslations.confirm.message.replace('false', changesToSyncronize) : employeeTranslations.confirm.message
                    self.$popup.confirm({
                        message: employeeTranslations.confirm.message,
                        // title: self.$t('popup.confirm.title'),
                        textSave: employeeTranslations.confirm.textSave,
                        textCancel: employeeTranslations.confirm.textCancel,
                        callSave: function () {
                            self.$popup.close()
                        },
                        callCancel: function () {
                            if (employee_id) {
                                self.$popup.disconnect({
                                    message: employeeTranslations.disconnect.message,
                                    textSave: employeeTranslations.disconnect.textSave,
                                    textCancel: employeeTranslations.disconnect.textCancel,
                                    callSave: function () {
                                        // self.$router.push('/login')
                                        self.$overlay.loading()
                                        self.$store.dispatch('login/logout', { employeeId: employee_id, location_id: self.location.id }).then(function () {
                                            self.$store.dispatch('login/clearLoginData').then(() => {
                                                self.$overlay.hide()
                                                if (false && process.env.VUE_APP_IS_APP == 'TRUE') {
                                                } else {
                                                    self.$router.push('/login').then(function () {
                                                        location.reload()
                                                    })
                                                }
                                            })
                                        })
                                    }
                                })
                            }
                        }
                    })
                } else {
                    if (employee_id) {
                        self.$popup.disconnect({
                            message: employeeTranslations.disconnect.message,
                            textSave: employeeTranslations.disconnect.textSave,
                            textCancel: employeeTranslations.disconnect.textCancel,
                            callSave: function () {
                                // self.$router.push('/login')
                                self.$overlay.loading()
                                self.$store.dispatch('login/logout', { employeeId: employee_id, location_id: self.location.id }).then(function () {
                                    self.$store.dispatch('login/clearLoginData').then(() => {
                                        self.$overlay.hide()
                                        if (false && process.env.VUE_APP_IS_APP == 'TRUE') {
                                        } else {
                                            self.$router.push('/login').then(function () {
                                                location.reload()
                                            })
                                        }
                                    })
                                })
                            }
                        })
                    }
                }
            })
        },

        userLogout(event) {
            event.preventDefault()
            event.stopPropagation()

            var self = this
            const employee_id = this.$store.getters['loginUser/getLocalEmployee']

            if (employee_id) {
                self.$store
                    .dispatch('loginUser/doLogout', {
                        employee: employee_id
                    })
                    .then(function (response) {
                        self.closeBox()
                        const isKitchenModeOn = localStorage.getItem('kitchenMode') && localStorage.getItem('kitchenMode') === 'true'

                        if (self.kitchenModeFeatureFlag && isKitchenModeOn) {
                            self.$router.push('/homewidgets').catch((err) => {})
                        } else {
                            self.$router.push(self.$root.redirectPath(self.home)).catch((err) => {
                                if (err.name == 'NavigationDuplicated') {
                                    self.$router.push('/reset' + self.redirectPath(self.home))
                                }
                            })
                        }
                    })
            }
        },

        // GENERAL METHODS
        closeBox() {
            this.$store.commit('setMenuUserVisible', false)
        },

        redirectPath(firstResult) {
            // console.log('redirect')
            return this.$root.redirectPath(firstResult)
        },

        toCurrentRoute() {
            let currentPath = this.$route.path
            let aux = this.$route.path.split('/')[1]
            let parentPath = typeof aux !== 'undefined' ? '/' + aux : false

            if (parentPath && parentPath != currentPath) {
                this.$router.push({ path: parentPath })
            }
        }
    }
}
</script>
// DESKTOP STYLES
<style lang="scss">
#user-box.overlay {
    background-color: transparent;
    z-index: 19000000;
    height: calc(100% - #{$menu-top-height});
    height: 100%;
    width: 100vw;
    position: absolute;
    top: $menu-top-height;
    top: 0;
    right: 0;

    .menu {
        width: 90vw;
        //height: calc(100% - #{$menu-top-height} + 1px);
        height: 100vh;
        background-color: $default-bg-color;
        position: absolute;
        right: 0;
        top: 0;
        max-width: 400px;
        z-index: 2000000;
        padding: 15px;
        @include default-box-shadow();

        .close-menu-icon {
            width: 100%;
            @include display-flex();
            @include justify-content(flex-start);
            @include align-items();
            margin-bottom: 10px;
            .icon {
                @include background($image: img('close_main_s20.svg'), $position: center 5px center, $size: 20px);
                width: 20px;
                height: 20px;
                cursor: pointer;
                margin-bottom: 8px;
            }
        }

        .user-info {
            @include display-flex();
            @include align-items();
            height: 90px;
            margin-bottom: 10px;
        }

        .user-actions {
            height: calc(100% - 100px);
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-gap: 15px;
            align-items: start;
            align-content: start;
            justify-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 0px;
            padding-bottom: 30px;
            overflow: auto;

            .action {
                @include border-radius(5px);
                @include display-flex();
                @include align-items();
                $action-height: 50px;
                background-color: #fff;
                width: 100%;
                height: $action-height;
                padding: 10px;
                cursor: pointer;

                .icon {
                    width: 40px;
                    height: 100%;
                    // @include background($position: left center, $size: 30px);
                    // background-position: center center;
                    // background-size: 30px;
                }

                .text {
                    width: calc(100% - 40px);
                    line-height: calc(#{$action-height} - 10px * 2);
                    font-family: $text;
                }

                &.profile {
                    .icon {
                        @include background($image: img('user_neutro_s70.svg'), $position: left 5px center, $size: 20px);
                    }
                }
                &.local {
                    .icon {
                        @include background($image: img('change_local.svg'), $position: left 5px center, $size: 20px);
                    }
                }
                &.logout {
                    .icon {
                        @include background($image: img('power_off_neutro_s70.svg'), $position: left 5px center, $size: 20px);
                    }
                }
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
#user-box.overlay.portrait {
    $menu-top-height: 60px;
    background-color: transparent;
    z-index: 19000000;
    height: calc(100% - #{$menu-top-height});
    height: 100%;
    width: 100vw;
    position: absolute;
    top: $menu-top-height;
    top: 0;
    right: 0;

    .menu {
        width: 90vw;
        height: calc(100% - #{$menu-top-height} + 1px);
        background-color: $default-bg-color;
        position: absolute;
        right: 0;
        // top: 0;
        top: 59px;
        max-width: 400px;
        z-index: 2000000;
        padding: 15px;

        .user-info {
            @include display-flex();
            @include align-items();
            height: 90px;
            margin-bottom: 10px;
        }

        .user-actions {
            height: calc(100% - 100px);
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-gap: 15px;
            align-items: start;
            align-content: start;
            justify-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 0px;
            padding-bottom: 30px;
            overflow: auto;

            .action {
                @include border-radius(5px);
                @include display-flex();
                @include align-items();
                $action-height: 50px;
                background-color: #fff;
                width: 100%;
                height: $action-height;
                padding: 10px;
                cursor: pointer;

                .icon {
                    width: 40px;
                    height: 100%;
                    // @include background($position: left center, $size: 30px);
                    // background-position: center center;
                    // background-size: 30px;
                }

                .text {
                    width: calc(100% - 40px);
                    line-height: calc(#{$action-height} - 10px * 2);
                    font-family: $text;
                }

                &.profile {
                    .icon {
                        @include background($image: img('user_neutro_s70.svg'), $position: left 5px center, $size: 20px);
                    }
                }
                &.logout {
                    .icon {
                        @include background($image: img('power_off_neutro_s70.svg'), $position: left 5px center, $size: 20px);
                    }
                }
            }
        }
    }
}
</style>
